html {
  scroll-behavior: smooth;
}

.profile-right-top .project-status {
  text-align: center;
}

a.anchor {
  display: block;
  position: relative;
  top: -250px;
  visibility: hidden;
}

.profile-right-top .project-status .title {
  color: #000f2b;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  padding-bottom: 15px;
}

.profile-right-top .project-status .status {
  color: #085280;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 29px;
  letter-spacing: 0;
  line-height: 19px;
  padding-bottom: 10px;
  padding-right: 0px;
}

.profile-right-top .project-status .date {
  color: #ada8a8;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 27px;
}

.project-status-container {
  height: 141px;
  width: auto;
  border-radius: 16px;
  background-color: rgba(17, 118, 181, 0.05);
  float: left;
  display: flex;
}

.project-status-container .project-status-right {
  margin: 18.5px 15px 0px 15px;
  max-width: 185px;
  width: -webkit-fill-available;
}

.project-status-container .project-status-right .project-title {
  color: #000f2b;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.project-status-container .project-status-right .project-date {
  color: #ada8a8;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.project-status-container .project-status-right .project-status {
  color: #085280;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
}

.status-title {
  height: 20px;
  width: 492px;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.change-status {
  height: 24px;
  width: 552px;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-top:16px;
  padding-bottom:24px;
}

.status-textarea {
  height: 108px;
  width: 130%;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3),
    0 0.5px 0 1px rgba(0, 0, 0, 0.2);
}

.reason-codes {
  width: 130%;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3),
    0 0.5px 0 1px rgba(0, 0, 0, 0.2);
  
}
.status-savebtn {
  height: 40px;
  width: 64px;
}

.status-dropdown {
  height: 40px;
  width: 220px;
}

.delete-project-button {
  border: unset;
  border-radius: 4px;
  background-color: #a62626;
  box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.3), 0 0.5px 0 1px #912121;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 8px 40px;
  padding: 0px 16px;
}

.delete-modal-body {
  padding: 24px !important;
}

.delete-modal-title {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 24px;
}

.delete-modal-info {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.delete-modal-footer {
  display: flex;
  justify-content: center;
}

.delete-modal-footer .cancel {
  border: unset;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3),
    0 0.5px 0 1px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 8px 16px;
}

.delete-modal-footer .delete {
  border: unset;
  border-radius: 4px;
  background-color: #bd2b2b;
  box-shadow: 0 0.5px 0.4px 0.2px rgba(0, 0, 0, 0.3), 0 0.2px 0 0.5px #a62626;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 8px 16px;
}

.delete-modal-footer .certify {
  border: unset;
  border-radius: 4px;
  background-color: #0f5af0;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3), 0 0.5px 0 1px #0e51d8;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 8px 16px;
}

.confirm-modal-body {
  padding: 24px !important;
}

.confirm-modal-title {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 24px;
}

.confirm-modal-info {
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.confirm-modal-footer {
  display: flex;
  justify-content: center;
}

.confirm-modal-footer .cancel {
  border: unset;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3),
    0 0.5px 0 1px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 8px 16px;
}

.confirm-modal-footer .delete {
  border: unset;
  border-radius: 4px;
  background-color: #bd2b2b;
  box-shadow: 0 0.5px 0.4px 0.2px rgba(0, 0, 0, 0.3), 0 0.2px 0 0.5px #a62626;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 8px 16px;
}

.confirm-modal-footer .certify {
  border: unset;
  border-radius: 4px;
  background-color: #0f5af0;
  box-shadow: 0 1px 0.8px 1px rgba(0, 0, 0, 0.3), 0 0.5px 0 1px #0e51d8;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 8px 16px;
}

.phase-pill {
  text-shadow: 0.2px 0 currentColor;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border-radius: 8px;
  box-shadow: 0 0.6px 3px 0 rgba(0, 0, 0, 0.2),
    0 0.3px 1.2px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin: -35px 0px 0px 16px;
  position: absolute;
  float: left;
  min-width: 8vw;
  padding-right: 10px;
}

.approved-phase-pill {
  text-shadow: 0.2px 0 currentColor;
  color: rgba(0, 0, 0, 0.82);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border-radius: 8px;
  box-shadow: 0 0.6px 3px 0 rgba(0, 0, 0, 0.2),
    0 0.3px 1.2px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin: -35px 16px 0px 0px;
  position: sticky;
  float: right;
  min-width: fit-content;
  padding-right: 10px;
}

.accordion-grid-parent .attributes{
  column-gap: 8px;
  align-items:flex-end;
}

.selectContainer{
  margin: 0px 3px;
}
  
.accordion-grid-parent .header{
  height: 56px;
}

.accordion-grid-parent .attri-title {
  color: #605B57;
}
  
.attributes-title-select{
  padding-left: 3px;
  vertical-align: middle;
}

.edit-profile-icon{
  color: rgb(17, 118, 181);
}

/*.MuiSvgIcon-root{
  color: rgb(17, 118, 181)!important;
}*/
.MuiButton-root .custom-restriction{
  text-transform: none;
  outline: auto;
  height: 24px;
  padding: 0;
  margin-bottom: 20px;
  padding-right: 5px;
  }

.reason-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    margin-top: 0.5em;
  }

  .clock {
    color: red;
    margin-right: 2px;
    margin-bottom: 3px;
    height: 24px !important;
    width: 24px !important;
  }


/* .App {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:scroll;
    margin:10px
} */
 
/* table {
    border: 1px solid black;
}
 
th {
    border-bottom: 1px solid black;
}
 
td {
    text-align: center;
    border-right: 1px solid black;
}

tr {
  border-bottom: 1px solid black;
} */