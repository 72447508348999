.title-container {
  margin-top: 64px;
  margin-left: 78px;
}

.title-container .chevron-left {
  height: 32px;
  width: 17px;
  color: #1176b5;
  vertical-align: text-bottom;
  margin-right: 16px;
}

.title-container .cookie-dash {
  font-family: 'Source Sans Pro', sans-serif;
  margin-right: 5px;
}

.title-container .cookie-divider {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
}

.title-container .update-cookie-dash {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.title-container .update-cookie-title {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: unset;
  letter-spacing: 0;
  line-height: 16px;
}

.title-container .project-id-container {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  margin-top: 6px;
}

.title-container .update-project-name {
  height: 38px;
  width: auto;
  display: inline-block;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin-right: 29px;
  margin-top: 5px;
  margin-bottom: 0px !important;
}

.title-container .cookie-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  margin-left: 5px;
}

.title-container .project-name {
  height: 38px;
  width: auto;
  display: inline-block;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  margin-right: 29px;
  margin-top: 5px;
}

.title-container .edit-profile-button {
  height: 40px;
  width: 170px;
  float: right;
  margin: 27px 70px 0px 0px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.title-container .edit-profile-icon {
  /* color: #1176b5; */
  color: #0C77BA;
  margin-bottom: 4px;
  margin-right: 2px;
}

.accordion-header-space .edit-profile-icon {
  color: #0C77BA;
  margin-bottom: 4px;
  margin-right: 2px;
}

.project-profile-top {
  height: 264px;
  width: auto;
  -webkit-transition: height 0.25s;
  -moz-transition: height 0.25s;
  transition: height 0.25s;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(121, 118, 118, 0.5);
  margin: 33px 66px 0px 74px;
  display: grid;
  grid-template-columns: 32.1% 20.84% 20.84% 26.23%;
  grid-template-rows: auto;
  grid-template-areas: "item-a item-b item-c item-d";
}

.project-profile-top-phase {
  height: max-content;
  width: auto;
  -webkit-transition: height 0.25s;
  -moz-transition: height 0.25s;
  transition: height 0.25s;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(121, 118, 118, 0.5);
  margin: 33px 66px 0px 74px;
  display: grid;

  /* column-gap: 10px; */
  /* grid-template-columns: 32.1% 22.9% 22% 23%; */
  grid-template-columns: 32.1% 22.5% 21.8% 23.6%;
  grid-template-rows: auto;
  grid-template-areas:
    "item-a-phase item-e-phase item-e-phase item-e-phase"
    "item-a-phase item-b-phase item-c-phase item-d-phase";
}

.empty-space-header {
  box-sizing: border-box;
  height: 0px;
  width: 0px;
  border-radius: 4px;
  background-color: #fefbfb;
  box-shadow: 0 0.1px 0.2px 0 rgba(0, 0, 0, 0.5);
}

.item-e-phase {
  box-sizing: border-box;
  grid-area: item-e-phase;
  height: 48px;
  width: auto;
  border: 1px solid #d02f2f;
  border-radius: 4px;
  background-color: #fefbfb;
  box-shadow: 0 0.1px 0.2px 0 rgba(0, 0, 0, 0.5);
}

.item-e-title {
  color: #d0021b;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  padding: 10px 10px 10px 16px;
}

.item-e-image {
  padding: 0px 8px 3px 0px;
}

.item-a {
  background-color: #f2f2f2;
  padding: 24px;
  height: 264px;
  border-radius: 16px 0px 0px 16px;
  position: relative;
}

.item-a-phase {
  background-color: #f2f2f2;
  grid-area: item-a-phase;
  padding: 24px;
  /* height: 264px; */
  border-radius: 16px 0px 0px 16px;
  position: relative;
}

.center-image {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-pill {
  border-radius: 28px;
  background-color: #e46026;
  margin: 16px 0px 0px 16px;
  position: absolute;
  float: left;
  width: auto;
}

.image-pill-established {
  border-radius: 28px;
  background-color: #0f9427;
  margin: 16px 0px 0px 16px;
  position: absolute;
  float: left;
  width: auto;
}

.image-pill-date {
  padding: 8px 61px 0px 16px;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.78vw;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  width: max-content;
}

.image-pill-type {
  padding: 8px 16px 8px 16px;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.07vw;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
}

.item-b {
  padding-top: 40px;
  padding-left: 40px;
}

.item-b-phase {
  grid-area: item-b-phase;
  padding-top: 40px;
  padding-left: 30px;
  margin-bottom: 40px;
}

.item-b-pers-container {
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0.6px 3px 0 rgba(0, 0, 0, 0.2),
    0 0.3px 1.2px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "item-b-container-top"
    "item-b-container-bottom";
}

.item-b-pers-container-top {
  background-color: #f2f2f2;
  border-radius: 8px 8px 0px 0px;
  padding: 16px 24px 16px 24px;
  height: fit-content;
}

.item-b-pers-container-bottom {
  padding: 5px 0px 0px 24px;
  height: fit-content;
  min-width: 216px;
  min-height: 91px;
}

.item-b-pers-container-bottom .top {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.item-b-pers-container-bottom .bottom {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.item-b-container {
  height: auto;
  width:auto;
  border-radius: 8px;
  box-shadow:0 2px 2px 0 rgb(121 118 118 / 50%);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "item-b-container-top"
    "item-b-container-bottom";
}

.item-b-container-top {
  background-color: #f2f2f2;
  border-radius: 8px 8px 0px 0px;
  padding: 16px 24px 16px 24px;
  height: fit-content;
}

.item-b-container-bottom {
  padding: 5px 0px 0px 24px;
  height: fit-content;
  /* min-width: 216px; */
  min-height: 91px;
  /* width: max-content; */
}

.item-b-container-bottom .top {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.item-b-container-bottom .bottom {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.item-c {
  padding-top: 40px;
  padding-left: 40px;
}

.item-c-phase {
  grid-area: item-c-phase;
  padding-top: 40px;
  padding-left: 10px;
}

.item-c-container {
  height: auto;
  /* max-width: 250px; */
  border-radius: 8px;
  box-shadow:0 2px 2px 0 rgb(121 118 118 / 50%);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "item-b-container-top"
    "item-b-container-bottom";
}

.item-c-container-top {
  background-color: #f2f2f2;
  border-radius: 8px 8px 0px 0px;
  padding: 16px 13px 16px 13px;
  height: fit-content;
  position: relative;
}

.item-c-container-top-edit {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.item-c-container-bottom {
  padding: 5px 0px 0px 24px;
  height: fit-content;
  min-height: 91px;
}

.item-c-container-bottom .top {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.item-c-container-bottom .bottom {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.item-d {
  padding-top: 40px;
  padding-left: 25px;
}

.item-d-phase {
  grid-area: item-d-phase;
  padding-top: 40px;
  margin-right:40px;
  padding-left:10px;
}

.item-d-container {
  height: auto;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgb(121 118 118 / 50%);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "item-d-container-top"
    "item-d-container-bottom";
}

.item-d-container-top {
  background-color: #f2f2f2;
  border-radius: 8px 8px 0px 0px;
  padding: 16px 24px 16px 24px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.item-d-container-button {
  background-color: #1176b5;
  border-radius: 4px;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border-color: #1176b5;
  border-style: none;
  padding: 8px 16px;
}

.item-d-container-bottom {
  padding: 5px 0px 0px 24px;
  height: fit-content;
  min-height: 91px;
}

.item-d-container-bottom .top {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.item-d-container-bottom .bottom {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  flex-direction: row;
}

.item-d-container-bottom .value {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 5px;
}

.sticky {
  height: 58px;
  width: auto;
  position: sticky;
  top: 0;
  z-index: 1020;
  -webkit-transition: height 0.35s;
  -moz-transition: height 0.35s;
  transition: height 0.35s;
  border-radius: 28px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(121, 118, 118, 0.5);
  margin: 33px 66px 0px 74px;
  display: grid;
  grid-template-columns: 29.69% auto;
  grid-template-rows: auto;
  grid-template-areas: "item-a-sticky item-b-sticky";
}

.item-a-sticky {
  display: none;
}

.item-b-sticky {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
}

.item-b-sticky .status {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 17px 8px 17px 16px;
}

.item-b-sticky .prompt {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 17px 0px;
}

.item-b-sticky .value {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  padding: 17px 13px 17px 5px;
}

.item-b-sticky .button-container {
  padding: 8px 24px 8px 0px;
}

.item-b-sticky .button-container .button {
  background-color: #1176b5;
  border-radius: 4px;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  border-color: #1176b5;
  border-style: none;
  padding: 8px 16px;
}

.item-a-sticky .pill {
  height: 58px;
  width: max-content;
  max-width: 33vw;
  border-radius: 28px;
  background-color: #e46026;
  display: flex;
  flex-direction: row;
}

.item-a-sticky .pill-established {
  height: 58px;
  width: max-content;
  max-width: 33vw;
  border-radius: 28px;
  background-color: #0f9427;
  display: flex;
  flex-direction: row;
}

.item-a-sticky .pill-established .date {
  padding: 18px 0px 18px 16px;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.item-a-sticky .pill-established .type {
  padding: 16px 16px 16px 11px;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.07vw;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
}

.item-a-sticky .pill .date {
  padding: 18px 0px 18px 16px;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.item-a-sticky .pill .type {
  padding: 16px 16px 16px 11px;
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.07vw;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
}

.eligi-content {
  display: flex;
  margin: 32px 0px 32px 69px;
}

.eligi-content .eligi-overview {
  display: flex;
  flex: 0.1;
}

.eligi-content .eligi-overview .eligi-overview-name {
  width: auto;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.eligi-content .eligi-overview .eligi-overview-semicolon {
  width: auto;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  margin-right: 5px;
}

.eligi-content .eligi-overview .eligi-overview-value {
  width: auto;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  font-weight: bold;
}

.eligi-content .eligi-overview .eligi-overview-status {
  color: #5aca49;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  float: left;
  font-weight: bold;
  padding-right: 25px;
}

.project-nav {
  margin: 60px 64px 30px 74px;
  padding: 0px !important;
  border-bottom: 4px solid #ada8a8;
  top: 6.4rem !important;
}

.project-nav .profile-nav-button {
  color: #1c6fa3 !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 32px !important;
}

.project-nav .profile-nav-button.active {
  color: #4a4949 !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  border-bottom: 4px solid;
  border-bottom-color: #ffb400;
  padding-bottom: 11px;
  margin-right: 32px !important;
}

.project-nav .download-xlsx {
  height: auto;
  width: auto;
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
}

.project-nav .download-xlsx:hover {
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
}

.project-nav .download-xlsx:focus {
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  outline-offset: none !important;
}

.collapse-all {
  height: auto;
  width: auto;
  margin: 0px 0px 8px 78px;
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
}

.collapse-all:hover {
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
}

.collapse-all:focus {
  color: #1c6fa3;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  outline-offset: none !important;
}

.collapse-all-icon {
  height: 15.12px;
  width: 20.12px;
  color: #0c77ba;
  padding-bottom: 2px;
}

.project-nav .download-icon {
  height: 20.2px;
  width: 20.2px;
  color: #0c77ba;
}

.carousel-control-prev-icon {
  background-image: url(../../images/left-arrow.svg) !important;
  background-color: #888888 !important;
  border-radius: 50%;
  height: 26px !important;
  width: 26px !important;
}

.carousel-control-next-icon {
  background-image: url(../../images/right-arrow.svg) !important;
  background-color: #888888 !important;
  border-radius: 50%;
  height: 26px !important;
  width: 26px !important;
}

.accordion-header-card {
  box-sizing: border-box;
  width: -webkit-fill-available;
  border: 1px solid #dee1e2 !important;
  border-radius: 0px !important;
  background-color: #f8f8f8 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: auto 66px 32px 78px;
  cursor: context-menu;
  user-select: text;
}

.accordion-header-card .accordion-header-space {
  padding: 0px;
  height: auto;
  display: flex;
}

.accordion-header-card .accordion-header-space .accordion-header-container {
  display: flex;
  flex-grow: 4;
}

.accordion-header-card
  .accordion-header-space
  .accordion-header-container
  .accordion-header-title {
  height: 56px;
  width: auto;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 0px;
  margin-left: 26px;
  padding-top: 17px;
}

.accordion-header-card
  .accordion-header-space
  .accordion-header-container
  .accordion-header-icon {
  height: auto;
  width: auto;
  color: #0c77ba;
  padding-left: 23px;
}

.accordion-header-card .accordion-header-space .upload-attachment-button {
  font-family: 'Source Sans Pro', sans-serif;
  height: 40px;
  width: 208px;
  margin: 8px 16px 8px 0px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.accordion-header-card .accordion-header-space .add-phase-button {
  height: 40px;
  width: auto;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0px 10px;
  margin: 8px 16px 8px 0px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.accordion-body-card {
  height: auto;
  width: auto;
  border: 0px solid #dee1e2;
  background-color: #ffffff;
  padding: 0px 0px 48px 0px !important;
  overflow: auto;
}

.accordion-body-card .accordion-body-card-container {
  height: auto;
  width: auto;
  display: flow-root;
}

.accordion-body-card .accordion-body-card-title {
  padding: 28px 0px 10px 69px;
  height: auto;
  width: auto;
  color: #0a5280;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 22px;
}

.accordion-body-card .accordion-body-card-left {
  float: left;
  height: auto;
  width: 481px;
  margin-left: 69px;
}

.accordion-body-card .accordion-body-card-right {
  float: left;
  height: auto;
  width: 481px;
  margin-left: 96px;
}

.accordion-body-card .accordion-body-eligi-left {
  height: auto;
  width: auto;
  margin-left: 69px;
  margin-right: 69px;
}

.accordion-body-card .eligibility-nav {
  margin: 32px 0px 32px 69px;
}

.accordion-body-card .eligibility-nav .eligibility-nav-link {
  padding: 0px 0px 11px 0px !important;
  margin-right: 32px;
  color: #1176b5;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.accordion-body-card .eligibility-nav .eligibility-nav-link.active {
  padding: 0px 0px 11px 0px !important;
  color: #2e2e2e !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  background-color: transparent !important;
  border-bottom: 4px solid #ffb400;
  border-radius: 0px;
}

.accordion-body-card .accordion-body-card-left .accordion-body-card-attributes {
  display: flex;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #edebe9;
}

.accordion-body-card
  .accordion-body-card-left
  .accordion-body-card-attributes
  .accordion-body-card-attributes-name {
  width: 240px;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.accordion-body-card
  .accordion-body-card-left
  .accordion-body-card-attributes
  .accordion-body-card-attributes-semicolon {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.accordion-body-card
  .accordion-body-card-left
  .accordion-body-card-attributes
  .accordion-body-card-attributes-value {
  width: 240px;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  padding-left: 30px;
  font-weight: bold;
}

.accordion-body-card
  .accordion-body-card-right
  .accordion-body-card-attributes {
  display: flex;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #edebe9;
}

.accordion-body-card
  .accordion-body-card-right
  .accordion-body-card-attributes
  .accordion-body-card-attributes-name {
  width: 240px;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.accordion-body-card
  .accordion-body-card-right
  .accordion-body-card-attributes
  .accordion-body-card-attributes-semicolon {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.accordion-body-card
  .accordion-body-card-right
  .accordion-body-card-attributes
  .accordion-body-card-attributes-value {
  width: 240px;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  padding-left: 30px;
  font-weight: bold;
}

.comment-form {
  height: auto;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 -1px 1px 1px rgba(0, 0, 0, 0.5);
}

.comment-form .form-name-input {
  height: 52px;
  min-width: 568px;
  width: auto;
  margin: 16px 0px 0px 126px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.comment-form .form-comment-input {
  height: 52px;
  min-width: 1136px;
  width: auto;
  margin: 8px 0px 0px 126px;
  border-radius: 4px;
  background-color: #fcfcfc;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
}

.comment-form .form-submit-icon {
  height: auto;
  width: auto;
  margin: 8px 0px 16px 126px;
  background-color: transparent;
  border-color: transparent;
}
.comment-form .form-submit-icon:focus {
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  outline-offset: none !important;
}

.gaint-div {
  height: 3000px;
}

.sticky-top {
  top: 0;
  z-index: 1020;
  background-color: #ffffff;
}

.checkbox-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.checkbox-modal::-webkit-scrollbar {
  display: none;
}

.checkbox-modal-header {
  padding: 21px 24px 8px 24px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6) !important;
}

.checkbox-modal-header-title {
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 25px !important;
}

.checkbox-modal-header-close {
  cursor: pointer;
  margin-top: 0px;
  font-size: 25px;
}

.checkbox-modal-body {
  margin: 0px 21px 25px 26px !important;
  color: #2e2e2e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 27px;
}

.checkbox-modal-body-comment {
  margin: 25px 0px 4px 0px !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
}

.checkbox-modal-body-name {
  margin: 0px 0px 4px 0px !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
}

.checkbox-modal-body-comment-1 {
  color: #2e2e2e;
  font-weight: bold;
  padding-right: 3px;
}

.checkbox-modal-body-comment-2 {
  color: #2e2e2e;
  padding-right: 3px;
}

.checkbox-modal-body-comment-3 {
  color: #d0021b;
}

.checkbox-modal-body-input {
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21);
  background-color: #fcfcfc;
}

.checkbox-modal-footer {
  border-radius: 0 0 4px 4px;
  background-color: #f8f8f8;
  justify-content: flex-start !important;
  border-top: 0px !important;
  padding: 12px 0px 12px 20px !important;
}

.checkbox-modal-footer-button-1 {
  border-radius: 4px;
  border: 0px !important;
  background-color: #1176b5 !important;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37), 0 0.1px 0 0.4px #0a649d !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 20px !important;
  margin-right: 10px;
}

.checkbox-modal-footer-button-2 {
  border-radius: 4px;
  background-color: #fcfcfc !important;
  box-shadow: 0 0.4px 0.6px 0 rgba(0, 0, 0, 0.37),
    0 0.1px 0 0.4px rgba(0, 0, 0, 0.21) !important;
  border: 0px !important;
  color: #2e2e2e !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 8px 20px !important;
}

/* [type="checkbox"] {
  display: none;
} */

.switches {
  color: var(--white);
  display: flex;
}

.switches li {
  position: relative;
  counter-increment: switchCounter;
}

.switches li:not(:last-child) {
  border-bottom: 1px solid var(--gray);
}

.switches li::before {
  content: counter(switchCounter);
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  font-size: 2rem;
  font-weight: bold;
  color: var(--pink);
}

.switches label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switches span:last-child {
  position: relative;
  width: 50px;
  height: 26px;
  border-radius: 15px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: var(--gray);
  transition: all 0.3s;
}

.switches span:last-child::before,
.switches span:last-child::after {
  content: "";
  position: absolute;
}

.switches span:last-child::before {
  left: 1px;
  top: 1px;
  width: 24px;
  height: 24px;
  background: var(--white);
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.3s;
}

.switches span:last-child::after {
  top: 50%;
  right: 8px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/uncheck-switcher.svg);
  background-size: 12px 12px;
}

.switches [type="checkbox"]:checked + label span:last-child {
  background-color: #1176b5;
}

.switches [type="checkbox"]:checked + label span:last-child::before {
  transform: translateX(24px);
}

.switches [type="checkbox"]:checked + label span:last-child::after {
  width: 14px;
  height: 14px;
  /*right: auto;*/
  left: 8px;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/checkmark-switcher.svg);
  background-size: 14px 14px;
}

@media screen and (max-width: 600px) {
  .switches li::before {
    display: none;
  }
}

.accordion-grid-parent {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "button button button button"
    "header header header restrictions"
    "attributes attributes attributes restrictions"
    "attributes attributes attributes restrictions"
    "attributes3 attributes3 attributes3 attributes3";
}

.accordion-grid-parent .bigbutton {
  grid-area: button;
  height: 52px;
  width: auto;
  margin-bottom: 24px;
}

.accordion-grid-parent .header {
  grid-area: header;
  padding-top: 0px !important;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ede8e9;
  width: auto;
  margin-left: 78.5px;
  padding-right: 100px;
}

.accordion-grid-parent .attri-container {
  justify-content: flex-end;
}

.accordion-grid-parent .attri-title {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  padding-bottom: 7px;
}

.accordion-grid-parent .attri-value {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 25px;
}

.accordion-grid-parent .restrictions {
  grid-area: restrictions;
  overflow: inherit;
  margin-left: 35px;
  margin-right: 38px;
}

.accordion-grid-parent .attributes {
  grid-area: attributes;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: 111px;
  margin-top: 28.03px;
  column-gap:"8px";
}


.accordion-grid-parent .attributes > * {
  flex: 1 1 250px;
}

.accordion-grid-parent .attributes2 {
  grid-area: attributes2;
}

.restrictions-red-parent {
  box-sizing: border-box;
  height: 249px;
  width: auto;
  border: 1px solid #d02f2f;
  border-radius: 4px;
  background-color: #fefbfb;
  box-shadow: 0 0.1px 0.2px 0 rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.restrictions-red-parent .restrictions-title {
  padding: 7.5px 0px 8.45px 16.5px;
  color: #d02f2f;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.restrictions-red-parent .restrictions-image {
  padding: 0px 8px 3px 0px;
}

.restrictions-red-parent .project-phase-restrictions {
  word-wrap: none;
}

.conditions-restrictions {
  margin-top: 35px;
}

.restrictions-conditions-parent {
  box-sizing: border-box;
  height: 350px;
  width: auto;
  border: 1px solid #0e51d8;
  border-radius: 4px;
  background-color: #fefbfb;
  box-shadow: 0 0.1px 0.2px 0 rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.restrictions-conditions-parent .restrictions-title {
  padding: 7.5px 0px 8.45px 16.5px;
  color: #0e51d8;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.restrictions-conditions-parent .restrictions-image {
  padding: 0px 8px 3px 0px;
}

.restrictions-conditions-parent .project-phase-restrictions {
  word-wrap: none;
}

.accordion-grid-body-card {
  height: auto;
  width: auto;
  border: 0px solid #dee1e2;
  background-color: #ffffff;
  padding: 0px 0px 48px 0px !important;
  overflow: auto;
}

.accordion-grid-body-card .add-restrictions-button {
  margin: 0px 40px;
  height: inherit;
  cursor: pointer;
  padding: 14px 26px;
  border-radius: 4px;
  background-color: #f8f8f8;
}

.accordion-grid-body-card .add-restrictions-button .button-image {
  padding: 0px 10px 0px 0px;
}

.accordion-grid-body-card .title {
  height: auto;
  width: auto;
  color: #0a5280;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 22px;
}

.accordion-grid-body-card .left {
  float: left;
  height: auto;
  width: 481px;
  margin-left: 69px;
}

.accordion-grid-body-card .right {
  float: left;
  height: auto;
  width: 481px;
  margin-left: 96px;
}

.accordion-grid-body-card .left .accordion-body-card-attributes {
  display: flex;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #edebe9;
}

.accordion-grid-body-card
  .left
  .accordion-body-card-attributes
  .accordion-body-card-attributes-name {
  width: 240px;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.accordion-grid-body-card
  .left
  .accordion-body-card-attributes
  .accordion-body-card-attributes-semicolon {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.accordion-grid-body-card
  .left
  .accordion-body-card-attributes
  .accordion-body-card-attributes-value {
  width: 240px;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  padding-left: 30px;
  font-weight: bold;
}

.accordion-grid-body-card .right .accordion-body-card-attributes {
  display: flex;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #edebe9;
}

.accordion-grid-body-card
  .right
  .accordion-body-card-attributes
  .accordion-body-card-attributes-name {
  width: 240px;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.accordion-grid-body-card
  .right
  .accordion-body-card-attributes
  .accordion-body-card-attributes-semicolon {
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
}

.accordion-grid-body-card
  .right
  .accordion-body-card-attributes
  .accordion-body-card-attributes-value {
  width: 240px;
  color: #121212;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  padding-left: 30px;
  font-weight: bold;
}

.edit-profile-icon{
  color: rgb(17,118,181);
}

.tool-tip{
  line-height: 1.5;
  font-size: 14;
}
.MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow{
  right: -13px!important;
  width: 2em!important;
  height: 3em!important;
  margin-top: -3px!important;
  margin-right: -0.71em!important;
  margin-bottom: 4px!important;
}